import React, { useCallback, useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BigNumber from "bignumber.js";
import format from "date-fns/format";

interface UndelegationItem {
  date: Date;
  wallet: string;
  amount: number;
}

interface PlotDataItem {
  date: string;
  amount: number;
}

function App() {
  const [data, setData] = useState<UndelegationItem[] | undefined>();
  const [period, setPeriod] = useState("D");
  const fetchData = useCallback(async () => {
    console.log(1);
    try {
      const { data } = await axios.get(
        "https://testnet-2.octo-node.xyz/undelegations.json",
        {
          headers: {
            // "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log(2);

      setData(
        data.map((item: any) => ({
          date: new Date(item.date),
          wallet: item.wallet,
          amount: item.amount,
        }))
      );
    } catch (e) {
      console.log(3);
      console.log({ e });
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const plotData = useMemo(() => {
    if (data) {
      return data.reduce(
        (accumulator, item) => {
          const dayKey = format(item.date, "yyyy-MM-dd");
          const hoursKey = format(item.date, "yyyy-MM-dd hh'h'");
          const weekKey = format(item.date, "yyyy II");
          const dayItemIndex = accumulator.day.findIndex(
            (i) => i.date === dayKey
          );
          if (dayItemIndex !== -1) {
            accumulator.day[dayItemIndex].amount =
              accumulator.day[dayItemIndex].amount +
              item.amount / Math.pow(10, 6);
          } else {
            accumulator.day.push({
              amount: item.amount / Math.pow(10, 6),
              date: dayKey,
            });
          }
          const hoursItemIndex = accumulator.day.findIndex(
            (i) => i.date === hoursKey
          );
          if (hoursItemIndex !== -1) {
            accumulator.hours[hoursItemIndex].amount =
              accumulator.hours[hoursItemIndex].amount +
              item.amount / Math.pow(10, 6);
          } else {
            accumulator.hours.push({
              amount: item.amount / Math.pow(10, 6),
              date: hoursKey,
            });
          }
          const weekItemIndex = accumulator.week.findIndex(
            (i) => i.date === weekKey
          );
          if (weekItemIndex !== -1) {
            accumulator.week[weekItemIndex].amount =
              accumulator.week[weekItemIndex].amount +
              item.amount / Math.pow(10, 6);
          } else {
            accumulator.week.push({
              amount: item.amount / Math.pow(10, 6),
              date: weekKey,
            });
          }
          return accumulator;
        },
        {
          hours: new Array<PlotDataItem>(),
          day: new Array<PlotDataItem>(),
          week: new Array<PlotDataItem>(),
        }
      );
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    console.log(JSON.stringify({ week: plotData?.week }, undefined, 8));
    if (plotData) {
      const max = Math.max(...plotData!.day.map((i) => i.amount));
      console.log({ max });
    }
  }, [plotData]);

  const selectedData = useMemo(() => {
    if (period === "W") return plotData?.week;
    if (period === "H") return plotData?.hours;
    return plotData?.day;
  }, [period, plotData]);

  return (
    <div className="App">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
          marginLeft: 60,
        }}
      >
        <div style={{ flexDirection: "row", marginBottom: 30 }}>
          {["W", "D", "H"].map((i, index) => (
            <button
              key={index}
              onClick={() => {
                setPeriod(i);
              }}
              style={{
                background: period === i ? "grey" : "white",
              }}
            >
              {i}
            </button>
          ))}
        </div>
        <ResponsiveContainer width="65%" height={500}>
          <BarChart data={selectedData} margin={{ left: 50 }}>
            <XAxis dataKey="date" />
            <YAxis
              dataKey={"amount"}
              // domain={[
              //   0,
              //   // Math.max(...(selectedData ?? []).map((i) => i.amount)),
              //   10,
              // ]}
            />
            <Tooltip />
            <Bar dataKey="amount" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default App;
